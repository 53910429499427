import React, { Fragment } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/pt';

import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";

import { useGetAll } from '../../../hooks/';

const SalesBySupplier = ({ apiUrl, rqModel, columns }) => {
    const suppliersQuery = useGetAll(apiUrl, rqModel);

    const downloadSellerStats = () => {
        /* Call XLSX */
        let wb = XLSX.utils.book_new();

        var myBookData = [];

        myBookData.push([
            "Código Fornecedor",
            "Fornecedor",
            "Encomendas",
            "Total",
        ])

        suppliersQuery.data.forEach((myModel) => {
            myBookData.push([
                myModel.lab && myModel.lab.length === 1 ? myModel.lab[0].code : 'indefinido',
                myModel.lab && myModel.lab.length === 1 ? myModel.lab[0].name : 'indefinido',
                myModel.nOrders,
                myModel.total.toFixed(2),
            ]);
        });

        var ws = sheet_from_array_of_arrays(myBookData);
        var defaultName = 'Listagem Loja MF'
        /* add worksheet to workbook */
        wb.SheetNames.push(defaultName);
        wb.Sheets[defaultName] = ws;
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)], { type: "" }), 'Loja_maisfarmacia_estatistica_fornecedor' + moment().format('YYYYMMDD') + ".xlsx")
    }

    return (
        <Fragment>
            {!suppliersQuery.isLoading ? (
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Vendas por Fornecedor</h5>
                                <span>{"Total de vendas por Fornecedor"}</span>
                                <div className="todo">
                                    <div className="todo-list-wrapper">
                                        <div className="todo-list-container">
                                            <div className="mark-all-tasks">
                                                <div className="mark-all-tasks-container">
                                                    <Button color="primary" onClick={downloadSellerStats}>{"Download"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    {/* <div className="feature-products" style={{ position: 'absolute', right: 50 }}>
                                        <span className="f-w-600 m-r-5">{"Data"}</span>
                                        <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => console.log(e.target.value)}>
                                            <select className="form-control btn-square" name="select">
                                                <option value="2021">{"2021"}</option>
                                                <option value="2020">{"2020"}</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <DataTableExtensions
                                        columns={columns}
                                        filterPlaceholder={"Procurar..."}
                                        filterHidden={false}
                                        export={false}
                                        print={false}
                                        data={suppliersQuery.data.map((supplier) => ({
                                            supplier: `${supplier.lab.length > 0 ? supplier.lab[0].name : "Não definido"}`,
                                            orders: supplier.nOrders,
                                            total: `${supplier.total.toFixed(2)} €`,
                                        }))}
                                    >
                                        <DataTable
                                            defaultSortField={'orders'}
                                            defaultSortAsc={false}
                                            noHeader
                                            columns={columns}
                                            data={suppliersQuery.data.map((supplier) => ({
                                                supplier: `${supplier.lab.length > 0 ? supplier.lab[0].name : "Não definido"}`,
                                                orders: supplier.nOrders,
                                                total: `${supplier.total.toFixed(2)} €`,
                                            }))}
                                            highlightOnHover={true}
                                            pointerOnHover={true}
                                            pagination={true}
                                            paginationPerPage={10}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <div className="loader-box loader-abs">
                    <div className="loader-7" />
                </div>
            )}
        </Fragment>
    )
}

export default SalesBySupplier

const datenum = (v, date1904) => {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

const sheet_from_array_of_arrays = (data, opts) => {
    var ws = {};
    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (var R = 0; R !== data.length; ++R) {
        for (var C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = { v: data[R][C] };
            if (cell.v == null) continue;
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}